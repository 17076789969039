import React from 'react'

const Allphoto = () => {
  return (
    <div className='xsm:w-full lsm:w-5/12 lg:w-3/12 xsm:h-[200px] lsm:h-[200px] lg:h-[200px] bg-zinc-700 flex items-center justify-center rounded-lg '>
        <h1 className='lg:text-4xl text-white xsm:text-3xl'>ছবি</h1>
      
    </div>
  )
}

export default Allphoto
