import React, { createContext, useContext } from 'react';
import { GiCow } from "react-icons/gi";
import { FaDonate } from "react-icons/fa";
import { IoSchool } from "react-icons/io5";
import { BiHandicap } from "react-icons/bi";
import { PiHandshakeLight } from "react-icons/pi";
import { PiHandCoinsDuotone } from "react-icons/pi";

const ShareDeta = createContext();

export const useDeta = () => useContext(ShareDeta);

export const DetaProvider = ({ children }) => {

const bigslide =[
  {
    title:"আমাদের প্রথম দান দাতা "

  }, 
  {
    title:"আমাদের প্রথম ঋণ গ্রহিতা  "

  }, 
  {
    title:"আমাদের প্রথম প্রতিষ্ঠাবার্ষিকী অনুষ্ঠান  "

  }, 
]




const donate =[
  {
    icon1:<FaDonate /> , title1:"উদারভাবে দান করুন" ,dic1:"গুরুত্বপূর্ণ উদ্দেশ্যগুলিকে সমর্থন করতে এবং ইতিবাচক পরিবর্তন আনতে উদারভাবে দান করুন।"

  },
  {
    icon1:<PiHandshakeLight /> , title1:" ঋণদাতা ও ঋণগ্রহীতা" ,dic1:"ঋণদাতা অর্থ সরবরাহ করেন, এবং ঋণগ্রহীতা সেই অর্থ ব্যবহার করে বিভিন্ন প্রয়োজন মেটান।"

  },
  {
  icon1:<PiHandCoinsDuotone /> , title1:" তহবিল সংগ্রহ ও ঋণ বিতরণ" , dic1:"তহবিল সংগ্রহ ও ঋণ বিতরণ একটি প্রতিষ্ঠানের অর্থনৈতিক উন্নয়ন এবং কার্যক্রমের জন্য অত্যন্ত গুরুত্বপূর্ণ।"

  },
  {
  icon1:<PiHandCoinsDuotone /> , title1:" ঋণ সংগ্রহ ও বিতরণ প্রতিবেদন" , dic1:"ঋণ সংগ্রহ ও বিতরণ প্রতিবেদন প্রতিষ্ঠানগুলোর অর্থনৈতিক কার্যক্রম বিশ্লেষণ তৈরিতে সহায়ক।"

  },

]
const service =[
  {
    icon:<GiCow />, title: "গবাদী পশু ঋণ" ,dic:"একটি গবাদী পশু ঋণ কৃষকদের উৎপাদনশীলতা বৃদ্ধির জন্য পশুসম্পদ অর্থায়ন প্রদান করে।"

  },
  {
    icon:<IoSchool />, title: "শিক্ষা সহায়তা ঋণ" ,dic:"শিক্ষা সহায়তা ঋণ হলো শিক্ষার্থীদের উচ্চশিক্ষা ও পেশাগত দক্ষতা অর্জনের জন্য আর্থিক সহায়তা প্রদান।"

  },
  {
    icon:<BiHandicap />, title: "প্রতিবন্ধী সহায়ক ঋণ" ,dic:"প্রতিবন্ধী সহায়ক ঋণ হলো শারীরিক  প্রতিবন্ধীদের আর্থিক স্বনির্ভরতা এবং জীবনমান উন্নয়নে সহায়ক।"

  },
  {
    icon:<BiHandicap />, title: "সুবিধাবঞ্চিত শিক্ষার সহায়তা" ,dic:"সুবিধাবঞ্চিত শিক্ষার সহায়তা শিশুদের শিক্ষার সুযোগ তৈরি করে, তাদের ভবিষ্যৎ গঠনে গুরুত্বপূর্ণ ভূমিকা রাখে।"

  },

]

const opnion=[
  {
    name:"মাহফুজুর রহমান জীম", dic:"যাকাতস্পিড এর মাধ্যমে আমাদের এলাকায় সুদ মুক্ত লোন দিয়ে দরিদ্র মানুষের জন্য কাজ করার জন্য আপনাদের উদোগে স্বাগতম জানাচ্ছি আশা করছি আগামী দিনে সারা দেশে আপানাদের কার্যকম সুফল বয়ে আনবে। "

  },
  {
    name:"তরিকুল ইসলাম শিমুল", dic:"যাকাতস্পিড এর মাধ্যমে আমাদের এলাকায় সুদ মুক্ত লোন দিয়ে দরিদ্র মানুষের জন্য কাজ করার জন্য আপনাদের উদোগে স্বাগতম জানাচ্ছি আশা করছি আগামী দিনে সারা দেশে আপানাদের কার্যকম সুফল বয়ে আনবে। "

  },
  {
    name:"তারেকুর রহমান সোহান", dic:"যাকাতস্পিড এর মাধ্যমে আমাদের এলাকায় সুদ মুক্ত লোন দিয়ে দরিদ্র মানুষের জন্য কাজ করার জন্য আপনাদের উদোগে স্বাগতম জানাচ্ছি আশা করছি আগামী দিনে সারা দেশে আপানাদের কার্যকম সুফল বয়ে আনবে। "

  },
]




return (
    <ShareDeta.Provider value={{bigslide, donate, service, opnion}}>
      {children}
    </ShareDeta.Provider>
  );

};